@charset "utf-8";

@import "variables";


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,input,textarea,button,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;font-weight:inherit;font-style:normal;vertical-align:baseline;}
body{-webkit-text-size-adjust:100%;line-height:1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
ul,ol{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:none}
a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;}
ins{background-color:#ff9;color:#000;text-decoration:none}
mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
del{text-decoration:line-through}
abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
table{border-collapse:collapse;border-spacing:0}
input,select{vertical-align:middle}
a{color:inherit;text-decoration:none;outline:none;}
img{-ms-interpolation-mode: bicubic;}

::selection{background:#000;color:#fff;}
::-moz-selection{background:#000;color:#fff;}

*{
	box-sizing: border-box;
}

html{
	position: relative;
	overflow-x: hidden;
	min-width: 320px;
}
body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: $fontSize;
	line-height: $lineHeight;
	letter-spacing: $letterSpacing;
	color: $textColor;
	background-color: #fff;
	overflow-x: hidden;
}
body[data-lang="en"]{
	font-family: $fontEn;
	font-weight: 300;
}
body[data-lang="jp"]{
	font-family: $fontBase;
}

img,
svg{
	width: 100%;
	height: auto;
}

.inner{
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 1300px;
	margin: auto;
	padding: 0 20px;
}
.inner.max1200{
	max-width: 1200px;
}
.inner.max1100{
	max-width: 1100px;
}

.in{
	position: relative;
}

.pc{display: none;}
.smt{display: block;}

.h-lg,
.h-md,
.h-sm{
	color: #000;
	font-family: $fontEn;
	font-weight: 400;
	line-height: 2;
	span{
		display: inline-block;
	}
}
.h-lg{
	font-size: 22px;
	font-family: $fontEnNarrow;
	font-weight: 500;
}
.h-md{
	font-size: 20px;
}
.h-sm{
	font-size: 16px;
}

.h-en{
	font-family: $fontEn;
	font-weight: 400;
}
.h-jp{
	font-family: $fontJp;
}

.sns{
	line-height: 1;
	ul{
		letter-spacing: -.4em;
		li{
			letter-spacing: normal;
			display: inline-block;
			vertical-align: middle;
			margin: 0 10px;
			a{
				display: block;
				line-height: 0;
				img{
					width: 40px;
					height: 40px;
					transition: transform .3s $easeCubic;
				}
			}
			a:hover{
				img{
					transform: scale(1.1);
				}
			}
		}
		li.sns_fb{
			margin: 0 5px;
		}
		li.sns_yt{
			margin: 0 15px;
		}
	}
}

header{
	position: relative;
	z-index: 2;
	padding: 20px 0;
}
h1{
	display: none;
}
#h-logo{
	display: inline-block;
	vertical-align: middle;
	width: 140px;
	line-height: 0;
}
#lang-switch{
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
	ul{
		letter-spacing: -.4em;
		li{
			letter-spacing: normal;
			display: inline-block;
			vertical-align: top;
			span,
			a{
				position: relative;
				display: block;
				width: 45px;
				height: 45px;
				background-color: #9d9d9d;
				transition: background-color .3s;
				letter-spacing: $letterSpacing;
				b{
					position: absolute;
					top: 50%;
					left: 50%;
					color: #fff;
					font-size: 18px;
					font-family: $fontEn;
					font-weight: 500;
					transform: translate(-50%, -50%);
				}
			}
			span{
				background-color: $baseColor;
			}
			a:hover{
				background-color: darken(#9d9d9d, 10%);
			}
		}
	}
}

main{
	display: block;
}

.sec{
	position: relative;
	padding: 12% 0 14%;
	.sec-in{
		position: relative;
		z-index: 2;
	}
	.sec-head{
		text-align: center;
		margin: 0 0 6%;
	}
	.sec-body{
		p:not(:first-of-type){
			margin: 20px 0 0;
		}
	}
}
.sec.wide-top{
	margin-top: 100px;
}

footer{
	text-align: center;
	color: #fff;
	background-color: $baseColor;
	padding: 60px 0 10px;
	#f-logo{
		width: 140px;
		line-height: 0;
		margin: auto;
	}
	.sns{
		margin: 30px 0 0;
	}
}

#copyright{
	text-align: center;
	font-size: 13px;
	margin: 40px 0 0;
}

#pagetop{
	display: none;
	cursor: pointer;
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 6;
	width: 70px;
	height: 70px;
	background-color: $baseColor;
	span{
		text-align: center;
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		i{
			color: #fff;
			font-size: 24px;
			line-height: 1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}


@media screen and (min-width: 600px) {
	
	body{
		font-size: 15px;
	}

	.h-lg{
		font-size: 26px;
	}
	.h-md{
		font-size: 22px;
	}
	.h-sm{
		font-size: 18px;
	}

	.sec{
		padding: 10% 0 12%;
		.sec-head{
			margin: 0 0 5%;
		}
	}

	footer{
		text-align: left;
		#f-logo,
		.sns{
			display: inline-block;
			vertical-align: middle;
		}
		.sns{
			margin: 0;
			text-align: right;
			width: calc(100% - 150px);
		}
	}

}


@media screen and (min-width: 800px) {
	body{
		font-size: 16px;
	}

	.pc{display: block;}
	.smt{display: none;}

	.h-lg{
		font-size: 30px;
	}
	.h-md{
		font-size: 26px;
	}
	.h-sm{
		font-size: 20px;
	}

	header{
		.sns{
			display: inline-block;
			vertical-align: middle;
			margin: 0 30px 0 0;
		}
	}
	#h-logo{
		margin: 0 60px 0 0;
	}
	#lang-switch{
		position: static;
		display: inline-block;
		vertical-align: middle;
		transform: translate(0,0);
	}
	
	.sec{
		padding: 8% 0 10%;
		.sec-head{
			margin: 0 0 4%;
		}
	}

	#pagetop{
		width: 80px;
		height: 80px;
	}

}


@media screen and (min-width: 1000px) {
	
	body{
		font-size: 17px;
	}

	.h-lg{
		font-size: 34px;
	}
	.h-md{
		font-size: 30px;
	}
	.h-sm{
		font-size: 22px;
	}

	.sec{
		padding: 6% 0 8%;
		.sec-head{
			margin: 0 0 3%;
		}
	}

}


@media screen and (min-width: 1200px) {
	
	body{
		font-size: 18px;
	}

	.h-lg{
		font-size: 38px;
	}
	.h-md{
		font-size: 34px;
	}
	.h-sm{
		font-size: 24px;
	}

	.sec{
		padding: 100px 0 120px;
		.sec-head{
			margin: 0 0 40px;
		}
	}

}


#loading{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
	background-color: #fff;
}

#loader{
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 9;
	width: 50px;
	height: 50px;
	transform: translate(-50%, -50%);
}


.key-fade{
	opacity: 0;
	transition: opacity 2s;
}
.key-fade.on{
	opacity: 1;
}

.key-fadeUp{
	position: relative;
	top: 50px;
	opacity: 0;
	transition: top 1s,
				opacity 1s;
}
.key-fadeUp.on{
	top: 0;
	opacity: 1;
}

.key-slide{
	position: relative;
	left: 100px;
	opacity: 0;
	transition: opacity 1s .5s,
				left 2s .5s $easeCubic;
}
.key-slide.on{
	opacity: 1;
	left: 0;
}

.key-scale{
	opacity: 0;
	transform: scale(1.5);
	transition: opacity 1s,
				transform 2s $easeCubic;
}
.key-scale.on{
	opacity: 1;
	transform: scale(1.05);
}


.key-title{
	position: relative;
	display: inline-block;
	letter-spacing: .5em;
	margin: auto;
	span{
		opacity: 0;
		padding-left: .5em;
		transition: opacity .65s .65s linear;
	}
}
.key-title:before,
.key-title:after{
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	width: 100%;
	height: 1px;
	background-color: #666;
	transform: translateY(-50%) skew(0deg) rotate(-360deg);
	transition: width .65s $easeCubic,
				height .65s .325s $easeCubic,
				transform .325s .65s linear;
}
.key-title:before{
	left: -20px;
}
.key-title:after{
	right: -20px;
}
.key-title.on{
	span{
		opacity: 1;
	}
}
.key-title.on:before{
	width: 1px;
	height: .5em;
	transform: translateY(-50%) skew(-20deg) rotate(0deg);
}
.key-title.on:after{
	width: 1px;
	height: .5em;
	transform: translateY(-50%) skew(-20deg) rotate(0deg);
}

.key-seq{
	> p,
	> li{
		position: relative;
		top: 50px;
		opacity: 0;
		transition: top .65s,
					opacity .65s;
	}
	> p.on,
	> li.on{
		top: 0;
		opacity: 1;
	}
}